



























































import Vue from 'vue'
import TenderService from '@/services/tender'
import CardTitle from '@/components/CardTitle.vue'

type ClientData = {
  clientId: number
  client: string
  endDate: string
  endTime: string
}

export default Vue.extend({
  name: 'ChooseClientButton',
  components: { CardTitle },
  model: { prop: 'showModal' },
  props: {
    procurementId: {
      type: Number,
      required: true,
      default: 0,
    },
    tenderType: {
      type: String,
      required: true,
      default: 'buy',
    },
  },
  data: () => ({
    dialog: false,
    clientId: 0,
    favoriteClients: [] as ClientData[],
  }),
  watch: {
    dialog: function (value) {
      if (value) {
        if (this.procurementId === 0) {
          this.$toast.error(this.$t('somethingWentWrong') as string)
        } else {
          this.fetchFavorites()
        }
      }
    },
  },
  computed: {
    makeBidUrl(): string {
      return this.tenderType === 'buy' ? '/buying-tenders' : '/selling-tenders'
    },
  },
  methods: {
    async fetchFavorites(): Promise<void> {
      const response = await TenderService.fetchFavorites(this.procurementId)
      if (response?.data?.data) {
        this.favoriteClients = response.data.data || []
      } else {
        this.favoriteClients = []
        this.$toast.error(this.$t('could not fetch favorite clients') as string)
      }
    },
    openBidInNewTab(id: number, clientId: number, client: string) {
      const url = `${this.makeBidUrl}/${id}/make-offer-on-behalf/?clientId=${clientId}&client=${client}`;
      window.open(url, '_blank')
    },
  },
})
