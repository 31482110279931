import api from '@/plugins/api'
import type {
  CreateTenderResponse,
  DeleteTenderResponse,
  FindTenderResponse,
  GetAllTendersResponse,
  UpdateTenderResponse,
  GenerateContractResponse,
  GetTenderOfferResponse,
  GenerateInvoiceResponse,
  GetPublishedTenderOfferResponse,
} from './types'
import { StatusResponse } from '@/services/types'

const TenderService = {
  all(query = ''): Promise<GetAllTendersResponse> {
    return api().get(`/procurements${query}`)
  },

  find(id: string | number): Promise<FindTenderResponse> {
    return api().get(`/procurements/${id}`)
  },

  publish(id: string | number): Promise<GetTenderOfferResponse> {
    return api().post(`/procurements/${id}/publish`)
  },

  offers(id: string | number): Promise<GetTenderOfferResponse> {
    return api().get(`/procurements/${id}/offers`)
  },

  winners(id: string | number): Promise<GetTenderOfferResponse> {
    return api().get(`/procurements/${id}/winners`)
  },

  published(id: string | number): Promise<GetPublishedTenderOfferResponse> {
    return api().get(`/procurements/${id}/ongoing`)
  },

  makeOffer(payload: Record<string, unknown>): Promise<FindTenderResponse> {
    return api().post(`/offers`, payload)
  },

  makeOfferOnBehalf(clientId: number, payload: Record<string, unknown>): Promise<FindTenderResponse> {
    return api().post(`/offers/on-behalf/${clientId}`, payload)
  },

  confirmBid(
    id: string | number,
    confirmedPower: number,
    powerExceededReason: string | null = null,
  ): Promise<FindTenderResponse> {
    return api().post(`/offers/lot-bids/${id}/confirm`, { confirmedPower, powerExceededReason })
  },

  revertBid(id: string | number): Promise<FindTenderResponse> {
    return api().post(`/offers/lot-bids/${id}/revert`)
  },

  complete(id: string | number): Promise<FindTenderResponse> {
    return api().put(`/procurements/${id}/complete`)
  },

  create(payload: Record<string, unknown>): Promise<CreateTenderResponse> {
    return api().post('/procurements', payload)
  },

  update(id: string | number, payload: Record<string, unknown>): Promise<UpdateTenderResponse> {
    return api().put(`/procurements/${id}`, payload)
  },

  delete(id: string | number): Promise<DeleteTenderResponse> {
    return api().delete(`/procurements/${id}`)
  },

  generateContract(id: string | number): Promise<GenerateContractResponse> {
    return api().post(`/contracts/${id}`)
  },

  sendContract(id: string | number): Promise<StatusResponse<undefined>> {
    return api().post(`/contracts/${id}/send`)
  },

  downloadContract(id: string | number): Promise<GenerateContractResponse> {
    return api().get(`/contracts/${id}/download`)
  },

  deleteContract(id: string | number): Promise<StatusResponse<undefined>> {
    return api().delete(`/contracts/${id}`)
  },

  generateInvoice(id: string | number): Promise<GenerateInvoiceResponse> {
    return api().post(`/contracts/invoices/${id}`)
  },

  sendInvoice(id: string | number): Promise<StatusResponse<undefined>> {
    return api().post(`/contracts/invoices/${id}/send`)
  },

  downloadInvoice(id: string | number): Promise<GenerateInvoiceResponse> {
    return api().get(`/contracts/invoices/${id}/download`)
  },

  deleteInvoice(id: string | number): Promise<StatusResponse<undefined>> {
    return api().delete(`/contracts/invoices/${id}`)
  },

  chooseFavoriteClient(id: string | number, clientId: string | number): Promise<StatusResponse<undefined>> {
    return api().put(`/procurements/${id}/clients/${clientId}/favorites`)
  },

  deleteFavoriteClient(id: string | number, clientId: string | number): Promise<StatusResponse<undefined>> {
    return api().delete(`/procurements/${id}/clients/${clientId}/favorites`)
  },

  fetchFavorites(id: string | number): Promise<StatusResponse<undefined>> {
    return api().get(`/procurements/${id}/favorites`)
  },
}

export default TenderService
