import api from '@/plugins/api'
import type {
  CreateClientResponse,
  DeleteClientResponse,
  FindClientResponse,
  GetAllClientResponse,
  UpdateClientResponse,
  DeactivateClientResponse,
  StatisticsClientResponse,
  CountriesResponse,
  AdminsResponse,
  DeleteClientDocumentResponse,
} from './types'

const ClientService = {
  all(query = ''): Promise<GetAllClientResponse> {
    return api().get(`/clients${query}`)
  },

  getAllNoPagination(): Promise<GetAllClientResponse> {
    return api().get(`/clients/all`)
  },

  find(id: string | number): Promise<FindClientResponse> {
    return api().get(`/clients/${id}`)
  },

  create(payload: Record<string, unknown>): Promise<CreateClientResponse> {
    return api().post('/clients', payload)
  },

  update(id: string | number, payload: Record<string, unknown>): Promise<UpdateClientResponse> {
    return api().put(`/clients/${id}`, payload)
  },

  delete(id: string | number): Promise<DeleteClientResponse> {
    return api().delete(`/clients/${id}`)
  },

  deactivate(id: string | number, payload: Record<string, unknown>): Promise<DeactivateClientResponse> {
    return api().post(`/clients/${id}/deactivate`, payload)
  },

  deactivationStatus(id: string | number): Promise<DeactivateClientResponse> {
    return api().get(`/clients/${id}/deactivation`)
  },

  confirmDeactivation(clientId: string | number, id: string | number): Promise<DeactivateClientResponse> {
    return api().put(`/clients/${clientId}/deactivation/${id}`)
  },

  statistics(): Promise<StatisticsClientResponse> {
    return api().get(`/clients/statistics`)
  },

  countries(): Promise<CountriesResponse> {
    return api().get(`/countries`)
  },

  admins(): Promise<AdminsResponse> {
    return api().get(`/clients/admins`)
  },

  deleteDocument(clientId: number, id: number): Promise<DeleteClientDocumentResponse> {
    return api().delete(`/clients/${clientId}/documents/${id}`)
  },
}

export default ClientService
