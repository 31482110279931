



















import Vue from 'vue'
import DatePicker from '@/components/DatePicker.vue'
import { TranslateResult } from 'vue-i18n'
import { TendersSearchFilters } from '@/views/Tender/types'
import ClearButton from '@/components/buttons/ClearButton.vue'

export default Vue.extend({
  name: 'TendersSearchFilters',
  components: { ClearButton, DatePicker },
  props: {
    searchParams: { type: Object, default: Function },
    disabledSearchBtn: { type: Boolean, default: false },
    procurementsType: { type: String, default: '' },
  },
  model: { prop: 'searchParams' },
  methods: {
    clear: function () {
      this.computedSearchParams.endDate = undefined
      this.computedSearchParams.startDate = undefined
      this.computedSearchParams.status = undefined
    },
  },
  computed: {
    computedSearchParams: {
      get(): TendersSearchFilters {
        return this.searchParams
      },
      set(value: TendersSearchFilters): void {
        this.$emit('input', value)
      },
    },
    tenderStatuses(): Array<{ label: TranslateResult; value: string }> {
      return [
        { label: this.$t('draft'), value: 'draft' },
        { label: this.$t('published'), value: 'published' },
        { label: this.$t('closed'), value: 'closed' },
      ]
    },
  },
  mounted() {
    this.computedSearchParams.type = this.procurementsType
  },
})
