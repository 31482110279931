



























































































import { formatDate, formatEvaluationTime } from '@/utils/helpers'
import { defineComponent, reactive } from '@vue/composition-api'
import type { Header } from '@/components/composables/useDataTable/types'
import { useDataTable } from '@/components/composables/useDataTable'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import CreateButton from '@/components/buttons/CreateButton.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import TenderService from '@/services/tender'
import TenderStatus from '@/views/Tender/partials/TenderStatus.vue'
import EvaluateButton from '@/components/buttons/EvaluateButton.vue'
import ShowButton from '@/components/buttons/ShowButton.vue'
import OfferOnBehalfButton from '@/components/buttons/OfferOnBehalfButton.vue'
import ChooseClientButton from '@/components/buttons/ChooseClientButton.vue'
import TendersSearchFilters from '@/views/Tender/partials/TendersSearchFilters.vue'
import { ProcurementStatus } from '@/services/myTender/types'

export default defineComponent({
  name: 'TendersList',
  setup() {
    const searchParams = reactive({ filters: { startDate: undefined, endDate: undefined, status: undefined } })
    const { items, totalRecords, loading, pagination, search, query, pages, fetchData } = useDataTable(
      TenderService,
      searchParams,
    )
    return {
      items,
      totalRecords,
      loading,
      pagination,
      search,
      query,
      pages,
      fetchData,
      formatDate,
      formatEvaluationTime,
      TenderService,
      searchParams,
      ProcurementStatus,
    }
  },
  components: {
    EvaluateButton,
    ShowButton,
    OfferOnBehalfButton,
    TenderStatus,
    DeleteButton,
    EditButton,
    CreateButton,
    TendersSearchFilters,
    ChooseClientButton,
  },
  props: {
    tendersType: {
      type: String,
      default: '',
    },
  },
  computed: {
    headers(): Array<Header> {
      return [
        { value: 'name', text: this.$t('tenderName'), sortable: true },
        { value: 'publishDate', text: this.$t('publishDate'), sortable: true },
        { value: 'endDate', text: this.$t('closureDate'), sortable: true },
        { value: 'offerValidityMinutes', text: this.$t('evaluationDuration'), sortable: true },
        { value: 'status', text: this.$t('status'), sortable: true },
        { value: 'actions', text: this.$t('actions'), sortable: false },
      ]
    },
    isBuyingTender(): boolean {
      return this.tendersType === 'buy'
    },
    isSellingTender(): boolean {
      return this.tendersType === 'sell'
    },
    redirectedFrom(): string {
      if (this.isBuyingTender) return 'BuyingTenders'
      if (this.isSellingTender) return 'SellingTenders'
      return ''
    },
    tendersListTitle() {
      if (this.isBuyingTender) return 'buyingTenders'
      if (this.isSellingTender) return 'sellingTenders'
      return ''
    },
    tendersListButtonText() {
      if (this.isBuyingTender) return 'addBuyingTender'
      if (this.isSellingTender) return 'addSellingTender'
      return ''
    },
    evaluationToRoute() {
      if (this.isBuyingTender) return 'EvaluateBuyingTender'
      if (this.isSellingTender) return 'EvaluateSellingTender'
      return ''
    },
  },
  methods: {
    canBeFavorite(itemStatus: ProcurementStatus) {
      const favoritableStatuses = [
        ProcurementStatus.PUBLISHED,
        ProcurementStatus.EVALUATING_OFFERS,
        ProcurementStatus.DECRYPTING_OFFERS,
      ]
      return favoritableStatuses.includes(itemStatus)
    },

    canOfferOnBehalf(itemStatus: ProcurementStatus) {
      const favoritableStatuses = [ProcurementStatus.PUBLISHED, ProcurementStatus.DECRYPTING_OFFERS]
      return favoritableStatuses.includes(itemStatus)
    },

    isViewable(status: ProcurementStatus) {
      const viewableStatuses = [
        ProcurementStatus.CLOSED,
        ProcurementStatus.APPROVED,
        ProcurementStatus.PUBLISHED,
        ProcurementStatus.EVALUATING_EXPIRED,
        ProcurementStatus.DECRYPTING_OFFERS,
      ]
      return viewableStatuses.includes(status)
    },
  },
})
